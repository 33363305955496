import React, { Suspense, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { connect } from "react-redux";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import "react-toastify/dist/ReactToastify.css";
import "../assets/scss/style.scss";

import { toast } from "react-toastify";
import Loader from "./layout/Loader";
import ScrollToTop from "./layout/ScrollToTop";
import * as actions from "../store/actions";

const AdminAuthLayout = Loadable({
  loader: () => import("./layout/AdminAuth"),
  loading: Loader,
});

const AdminDashboard = Loadable({
  loader: () => import("./layout/AdminDashboard"),
  loading: Loader,
});

const App = (props) => {
  useEffect(() => {
    props.autoAdminSignIn();

    toast.configure({
      style: { fontWeight: "bold" },
      className: "text-center",
    });
  }, []);

  if (props.isAdminLoading) {
    return <Loader />;
  }

  return (
    <ScrollToTop>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            path="/"
            component={props.isAdminLoggedIn ? AdminDashboard : AdminAuthLayout}
          />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
};

const mapStateToProps = (state) => {
  return {
    isAdminLoggedIn: state.adminAuth.isLoggedIn,
    isAdminLoading: state.adminAuth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    autoAdminSignIn: () => dispatch(actions.adminAuthCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
