import {
  ADMIN_AUTH_START,
  ADMIN_AUTH_SUCCESS,
  ADMIN_AUTH_FAIL,
  ADMIN_AUTH_LOGOUT,
  SET_ADMIN_REDIRECT_PATH,
  UNSET_ADMIN_ERROR,
} from "../types";

const initialState = {
  token: null,
  userId: null,
  userName: "",
  error: null,
  loading: false,
  isLoggedIn: false,
  authRedirectPath: "/admin",
  successMsg: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_AUTH_START:
      return {
        ...state,
        error: null,
        loading: true,
        isLoggedIn: false,
        successMsg: null,
      };
    case ADMIN_AUTH_SUCCESS:
      return {
        ...state,
        token: action.idToken,
        userId: action.userId,
        userName: action.userName,
        error: null,
        loading: false,
        isLoggedIn: true,
        authRedirectPath: "/home",
      };
    case ADMIN_AUTH_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        isLoggedIn: false,
      };
    case ADMIN_AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        loading: false,
        userId: null,
        isLoggedIn: false,
      };
    case SET_ADMIN_REDIRECT_PATH:
      return {
        ...state,
        authRedirectPath: action.path,
      };
    case UNSET_ADMIN_ERROR:
      return {
        ...state,
        error: null,
        successMsg: null,
      };
    default:
      return state;
  }
};

export default reducer;
