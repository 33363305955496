import axios from "axios";
import {
	COLLAPSE_MENU,
	COLLAPSE_TOGGLE,
	FULL_SCREEN,
	FULL_SCREEN_EXIT,
	CHANGE_LAYOUT,
	NAV_CONTENT_LEAVE,
	NAV_COLLAPSE_LEAVE,
	SET_DB_ENV
} from '../types';


export const openFullScreen = () => {return { type: FULL_SCREEN }}
export const exitFullScreen = () => {return { type: FULL_SCREEN_EXIT }}
export const collapseMenu = () => {return { type: COLLAPSE_MENU }}
export const changeLayout = (layout) => {return { type: CHANGE_LAYOUT, layout: layout }}
export const collapseToggle = (menu) => {return { type: COLLAPSE_TOGGLE, menu: menu }}
export const navContentLeave = () => {return { type: NAV_CONTENT_LEAVE }}
export const navCollapseLeave = (menu) => {return { type: NAV_COLLAPSE_LEAVE, menu: menu }}

export const setDBEnvironment = (env, token = false) => {
  if(token) localStorage.setItem("adminToken", token);
	return { type: SET_DB_ENV, payload: env }
}

export const changeEnvironment = (env) => {
  return (dispatch) => {
		axios({
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
			url: `${process.env.REACT_APP_API_URL}/admin/changeToken`,
			data: {env}
    })
    .then((response) => {
      let {token} = response.data;
      dispatch(setDBEnvironment(env, token));
    })
    .catch((err) => console.log(err));
  };
}