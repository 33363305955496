// template
export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SET_DB_ENV = "SET_DB_ENV";
// auth
export const UNSET_ERROR = "UNSET_ERROR";
export const SEND_MSG = "SEND_MSG";
// ADMIN_AUTH
export const ADMIN_AUTH_SUCCESS = "ADMIN_AUTH_SUCCESS";
export const ADMIN_AUTH_FAIL = "ADMIN_AUTH_FAIL";
export const ADMIN_AUTH_START = "ADMIN_AUTH_START";
export const ADMIN_AUTH_LOGOUT = "ADMIN_AUTH_LOGOUT";
export const SET_ADMIN_REDIRECT_PATH = "SET_ADMIN_REDIRECT_PATH";
export const UNSET_ADMIN_ERROR = "UNSET_ADMIN_ERROR";