import axios from "axios";
// import JWT from "expo-jwt";

import { setDBEnvironment } from "./templateActions";
import {
  ADMIN_AUTH_START,
  ADMIN_AUTH_SUCCESS,
  ADMIN_AUTH_FAIL,
  ADMIN_AUTH_LOGOUT,
  SET_ADMIN_REDIRECT_PATH,
  UNSET_ADMIN_ERROR,
} from "../types";

const expiresIn = 86400;

function authStart() {
  return { type: ADMIN_AUTH_START };
}

export const unsetAdminError = () => ({ type: UNSET_ADMIN_ERROR });

function setAdminLocalStorage(token, userId) {
  const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
  localStorage.setItem("adminToken", token);
  localStorage.setItem("adminId", userId);
  localStorage.setItem("adminExpirationDate", expirationDate);
}

function adminAuthSuccess(token, userId, userName) {
  return { type: ADMIN_AUTH_SUCCESS, token, userId, userName };
}

function authFail(err) {
  let error;
  // in case of backend error
  if (err.response) {
    error = err.response.data;
  }
  // in case of frontend error
  else if (err.message) {
    error = err;
  }
  // any other error (e.g: no connection)
  else {
    error = { message: "Something wrong happened. Please contact us" };
  }
  return { type: ADMIN_AUTH_FAIL, error };
}

export const adminLogout = () => {
  localStorage.clear();
  return { type: ADMIN_AUTH_LOGOUT };
};

function checkAuthTimeout(expirationTime) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(adminLogout());
    }, expirationTime * 1000);
  };
}

export const setAdminRedirectPath = (path) => {
  return { type: SET_ADMIN_REDIRECT_PATH, path: path };
};

export const verifyToken = (token) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/auth/verify`,
      headers: { Authorization: `Bearer ${token}` },
      data: {},
    })
      .then((response) => {
        console.log(response);
        let { userId, userName, email } = response.data;
        setAdminLocalStorage(token, userId, email);
        dispatch(adminAuthSuccess(token, userId, userName));
        dispatch(checkAuthTimeout(expiresIn));
      })
      .catch((err) => {
        console.error(err);
        const error = err.response.data.message || "Invalid token";
        dispatch(authFail(error));
      });
  };
};

export const adminAuthCheckState = () => {
  return (dispatch) => {
    dispatch(authStart());
    const token = localStorage.getItem("adminToken");
    if (!token) {
      dispatch(adminLogout(false));
    } else {
      const expirationDate = new Date(
        localStorage.getItem("adminExpirationDate")
      );
      if (expirationDate <= new Date()) {
        dispatch(adminLogout());
      } else {
        const userId = localStorage.getItem("adminId");
        // let payload = JWT.decode(token, process.env.REACT_APP_JWT_SECRET);
        // verify token
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/auth/verify`,
          headers: { Authorization: `Bearer ${token}` },
          data: {},
        })
          .then((response) => {
            console.log(response);
            let { userName, db } = response.data;
            dispatch(adminAuthSuccess(token, userId, userName));
            dispatch(setDBEnvironment(db));
            dispatch(
              checkAuthTimeout(
                (expirationDate.getTime() - new Date().getTime()) / 1000
              )
            );
          })
          .catch((err) => {
            console.error(err);
            const error = err.response.data.message || "Invalid token";
            dispatch(authFail(error));
          });
      }
    }
  };
};

export const adminLogin = ({ email, password }) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = { email, password };
    let url = `${process.env.REACT_APP_API_URL}/auth/adminlogin`;
    axios
      .post(url, authData)
      .then((response) => {
        let { token, userId, userName } = response.data;
        setAdminLocalStorage(token, userId, email);
        dispatch(adminAuthSuccess(token, userId, userName));
        dispatch(checkAuthTimeout(expiresIn));
      })
      .catch((err) => dispatch(authFail(err)));
  };
};
